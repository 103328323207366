// import 'selectric';
require('selectric')('selectric-sel', $);

export default class Select {
    constructor() { }

    init() {
        $('.selectric-sel').selectric({
            multiple: {
                separator: ', ',       // Type: String.             Description: Items separator.
                keepMenuOpen: true,    // Type: Boolean.            Description: Close after an item is selected.
                maxLabelEntries: false // Type: Boolean or Integer. Description: Max selected items do show.
            }
        });
    }
}
