(() => {
    const hamburger = document.querySelector('.js-hamburger');

    hamburger && hamburger.addEventListener('click', onClick);

    function onClick(e) {
        e.preventDefault();
        
        const target = this;
        const nav = document.querySelector('.js-navigation');
        const isActive = target.classList.contains('is-active');

        if (isActive) {
            target.classList.remove('is-active');
            nav.classList.remove('is-active');
        } else {
            target.classList.add('is-active');
            nav.classList.add('is-active');
        }
    }
})();
