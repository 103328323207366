// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import Select from '../_modules/select/select';
import '../_modules/hamburger/hamburger';
import 'slick-carousel';

$(() => {
    new Select().init();
    console.log('Welcome to Yeogurt!');

    // Mobile Drop Menu

    $('.main-menu__dropdown').on('click', function() {
        $(this).toggleClass('open');
        $('.top-logo').toggleClass('open');
        $('.main-menu').stop().slideToggle(200);
        setTimeout(function() {
            $('.main-menu').toggleClass('open');
        }, 200);
    });

    // Slider Team
    $('.slider-team').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
});
